import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { getOrders } from '../api'

export default function Orders() {
  const { data } = useQuery({
    queryKey: ['beerData'],
    queryFn: getOrders,
    retry: false,
    refetchOnWindowFocus: false,
  })
  console.log('orders=>>>', data)
  return <div>Orders</div>
}
