import React from 'react'
import { faker } from '@faker-js/faker'
import { useQuery } from '@tanstack/react-query'
import { Button, Space, Table } from 'antd'
import moment from 'moment'
import { getProducts } from '../api'
import { useState } from 'react'

export default function Products() {
  const { data } = useQuery({
    queryKey: ['beerData'],
    queryFn: getProducts,
    retry: false,
    refetchOnWindowFocus: false,
  })

  console.log('products=>>>', data)
  const dataTable = data?.data?.map((el) => ({
    key: faker.string.uuid(),
    supplierId: el.supplierId,
    name: el.name,
    categoryId: el.categoryId.name,
    countryAndManufacturer: el.country + ' / ' + el.manufacturer,
    parameters:
      el.alcoholVolume +
      ' / ' +
      el.packSize +
      ' / ' +
      el.priceRub +
      ' / ' +
      el.volumeL +
      ' / ' +
      el.weightKg,

    created: moment(el.created).format('YYYY-MM-DD'), // Форматирование даты создания
    modified: moment(el.modified).format('YYYY-MM-DD'), // Форматирование даты изменения
  }))
  const [filteredInfo, setFilteredInfo] = useState({})
  const [sortedInfo, setSortedInfo] = useState({})
  const handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter)
    setFilteredInfo(filters)
    setSortedInfo(sorter)
  }
  const clearFilters = () => {
    setFilteredInfo({})
  }
  const clearAll = () => {
    setFilteredInfo({})
    setSortedInfo({})
  }

  const columns = [
    {
      title: 'Артикул',
      dataIndex: 'supplierId',
      key: 'supplierId',
      sorter: (a, b) =>
        a.supplierId.localeCompare(b.supplierId, 'en', { numeric: true }),
      sortOrder:
        sortedInfo.columnKey === 'supplierId' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => {
        const nameA = a.name.toLowerCase()
        const nameB = b.name.toLowerCase()

        if (isNaN(nameA) && isNaN(nameB)) {
          return nameA.localeCompare(nameB)
        }

        return isNaN(nameA) ? 1 : -1
      },
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Категория',
      dataIndex: 'categoryId',
      key: 'categoryId',
      sorter: (a, b) => {
        const categoryA = a.categoryId.toLowerCase()
        const categoryB = b.categoryId.toLowerCase()
        return categoryA.localeCompare(categoryB, 'ru')
      },
      sortOrder:
        sortedInfo.columnKey === 'categoryId' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Страна/Производитель',
      dataIndex: 'countryAndManufacturer',
      key: 'countryAndManufacturer',
    },
    {
      title: 'Параметры',
      dataIndex: 'parameters',
      key: 'parameters',
    },
    {
      title: 'Создан',
      dataIndex: 'created',
      key: 'created',
      sorter: (a, b) => moment(a.created).unix() - moment(b.created).unix(),
      sortOrder: sortedInfo.columnKey === 'created' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Изменен',
      dataIndex: 'modified',
      key: 'modified',
      sorter: (a, b) => moment(a.modified).unix() - moment(b.modified).unix(),
      sortOrder: sortedInfo.columnKey === 'modified' ? sortedInfo.order : null,
      ellipsis: true,
    },
  ]
  return (
    <>
      <Space
        style={{
          marginBottom: 16,
        }}
      >
        <Button onClick={clearFilters}>Clear filters</Button>
        <Button onClick={clearAll}>Clear filters and sorters</Button>
      </Space>
      <Table columns={columns} dataSource={dataTable} onChange={handleChange} />
    </>
  )
}
