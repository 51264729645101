import { useQuery } from '@tanstack/react-query'
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import React, { useState, useEffect } from 'react'
import { getClients } from '../api'
import { Input, Button, Space, Table, Tag } from 'antd'
import { faker } from '@faker-js/faker'
import moment from 'moment'
import axios from 'axios'

const API_BASE_URL = 'https://svamgroup.ru/api'

export default function Clients() {
  const { data, refetch } = useQuery({
    queryKey: ['beerData'],
    queryFn: getClients,
    retry: false,
    refetchOnWindowFocus: false,
    enabled: true, // автоматическое выполнение запроса
  })

  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')

  useEffect(() => {
    const timer = setTimeout(() => {
      refetch()
    }, 500)

    return () => {
      clearTimeout(timer)
    }
  }, [searchText, searchedColumn, refetch])

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const dataTable = data?.data?.map((el) => ({
    key: faker.string.uuid(),
    id: el._id,
    status: el.status,
    legalName: el.legalName,
    inn: el.inn,
    email: el.email,
    phone: el.phone,
    contactName: el.contactName,
    created: moment(el.created).format('YYYY-MM-DD HH:mm'), // Форматирование даты создания
    modified: moment(el.modified).format('YYYY-MM-DD HH:mm'), // Форматирование даты изменения
  }))

  const [filteredInfo, setFilteredInfo] = useState({})
  const [sortedInfo, setSortedInfo] = useState({})

  const handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter)
    setFilteredInfo(filters)
    setSortedInfo(sorter)
  }
  const clearFilters = () => {
    setFilteredInfo({})
  }
  const clearAll = () => {
    setFilteredInfo({})
    setSortedInfo({})
  }

  const logout = () => {
    localStorage.removeItem('token')
    window.location.reload()
  }

  const handleToggleStatus = async (id) => {
    const token = localStorage.getItem('token')
    // Обработка нажатия чекбокса и вызов API
    try {
      const response = await fetch(
        `${API_BASE_URL}/clients/client/toggle-status?id=${id}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const data = await response.json()
      refetch()
    } catch (error) {
      console.error('Error toggling status:', error)
    }
  }

  let searchInput = null

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const columns = [
    {
      title: 'Вкл/выкл',
      dataIndex: 'status',
      key: 'toggleStatus',
      width: '6%',
      render: (status, record) => (
        <input
          type="checkbox"
          checked={status === 'active'}
          onChange={() => handleToggleStatus(record.id)}
        />
      ),
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      width: '8%',
      render: (status) => {
        let color = ''
        let text = ''
        if (status === 'notInitialized') {
          color = 'red'
          text = 'Не иниц.'
        } else if (status === 'disabled') {
          color = 'orange'
          text = 'Отключен'
        } else if (status === 'active') {
          color = 'green'
          text = 'Активен'
        }
        return <Tag color={color}>{text}</Tag>
      },
    },
    {
      title: 'Название',
      dataIndex: 'legalName',
      key: 'legalName',
      width: '17%',
      sorter: (a, b) => a.legalName - b.legalName,
      sortOrder: sortedInfo.columnKey === 'legalName' ? sortedInfo.order : null,
      ellipsis: true,
      ...getColumnSearchProps('legalName'),
    },
    {
      title: 'ИНН',
      dataIndex: 'inn',
      key: 'inn',
      width: '9%',
      sorter: (a, b) => a.inn - b.inn,
      sortOrder: sortedInfo.columnKey === 'inn' ? sortedInfo.order : null,
      ellipsis: true,
      ...getColumnSearchProps('inn'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '15%',
      sorter: (a, b) => a.email - b.email,
      sortOrder: sortedInfo.columnKey === 'email' ? sortedInfo.order : null,
      ellipsis: true,
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      key: 'phone',
      width: '10%',
      sorter: (a, b) => a.phone - b.phone,
      sortOrder: sortedInfo.columnKey === 'phone' ? sortedInfo.order : null,
      ellipsis: true,
      ...getColumnSearchProps('phone'),
    },
    {
      title: 'Контактное лицо',
      dataIndex: 'contactName',
      key: 'contactName',
      width: '15%',
      sorter: (a, b) => a.contactName - b.contactName,
      sortOrder:
        sortedInfo.columnKey === 'contactName' ? sortedInfo.order : null,
      ellipsis: true,
      ...getColumnSearchProps('contactName'),
    },
    {
      title: 'Создан',
      dataIndex: 'created',
      key: 'created',
      sorter: (a, b) => moment(a.created).unix() - moment(b.created).unix(),
      sortOrder: sortedInfo.columnKey === 'created' ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: 'Изменен',
      dataIndex: 'modified',
      key: 'modified',
      sorter: (a, b) => moment(a.modified).unix() - moment(b.modified).unix(),
      sortOrder: sortedInfo.columnKey === 'modified' ? sortedInfo.order : null,
      ellipsis: true,
    },
  ]

  return (
    <>
      <Space
        style={{
          marginBottom: 16,
          paddingLeft: '15px',
          paddingTop: '15px',
          // display: 'flex',
          // justifyContent: 'space-between',
        }}
      >
        <Button onClick={clearFilters}>Clear filters</Button>
        <Button onClick={clearAll}>Clear filters and sorters</Button>
      </Space>
      <Table columns={columns} dataSource={dataTable} onChange={handleChange} />
    </>
  )
}
