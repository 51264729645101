import {
  FolderOpenOutlined,
  LineChartOutlined,
  NotificationOutlined,
  PoweroffOutlined,
  ProfileOutlined,
  ShoppingCartOutlined,
  SolutionOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Menu } from 'antd'
import { useState } from 'react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
//

const Navbar = () => {
  const navigate = useNavigate()
  const handleLogout = () => {
    localStorage.removeItem('token')
    window.location.reload()
    navigate('/')
  }
  const items = [
    // {
    //   label: 'BEER INSIDERS',
    //   key: 'logo',
    //   icon: <UserOutlined />,
    // },
    // {
    //   label: <a href="/report">Отчеты</a>,
    //   key: 'statistics',
    //   icon: <LineChartOutlined />,
    // },
    {
      label: <a href="/">Клиенты</a>,
      key: 'сlients',
      icon: <UsergroupAddOutlined />,
    },
    // {
    //   label: <a href="/users">Пользователи</a>,
    //   key: 'users',
    //   icon: <UserOutlined />,
    // },
    // // {
    // //   label: <a href="/products">Каталог</a>,
    // //   key: 'products',
    // //   icon: <ProfileOutlined />,
    // // },
    // // {
    // //   label: <a href="/groups">Группы приложений</a>,
    // //   key: 'groups',
    // //   icon: <ShoppingCartOutlined />,
    // // },
    // // {
    // //   label: <a href="/podborka">Подборки</a>,
    // //   key: 'podborka',
    // //   icon: <FolderOpenOutlined />,
    // // },
    // // {
    // //   label: <a href="/notifications">Уведомления</a>,
    // //   key: 'notifications',
    // //   icon: <NotificationOutlined />,
    // // },
    {
      label: <a href="/users">Пользователи и доступ</a>,
      key: 'users',
      icon: <UserOutlined />,
    },
    {
      label: <button onClick={handleLogout}>Выйти</button>,
      key: 'Выйти',
      icon: <PoweroffOutlined className="icon" />,
    },
    // {
    //   key: 'SubMenu',
    //   icon: <UserOutlined />,
    //   children: [
    //     {
    //       type: 'group',
    //       // label: 'Item 1',
    //       children: [
    //         {
    //           label: 'Профиль',
    //           key: 'Профиль',
    //           icon: <SolutionOutlined className="icon" />,
    //         },
    //         {
    //           label: <button onClick={handleLogout}>Выйти</button>,
    //           key: 'Выйти',
    //           icon: <PoweroffOutlined className="icon" />,
    //         },
    //       ],
    //     },
    //   ],
    // },
  ]

  const [current, setCurrent] = useState('user')
  const onClick = (e) => {
    console.log('click ', e)
    setCurrent(e.key)
  }

  return (
    <>
      <Menu
        onClick={onClick}
        selectedKeys={[current]}
        mode="horizontal"
        items={items}
      />
    </>
  )
}
export default Navbar
