import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { getUsers } from '../api'
import { Space, Table, Tag } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { Button, Input } from 'antd'
import { useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { faker } from '@faker-js/faker'

export default function Users() {
  const { data, refetch } = useQuery({
    queryKey: ['beerData'],
    queryFn: getUsers,
    retry: false,
    refetchOnWindowFocus: false,
    enabled: true, // автоматическое выполнение запроса
  })
  console.log('users=>>>', data)

  const dataTable = data?.map((el) => ({
    key: faker.string.uuid(),
    isActive: el.isActive,
    username: el.name,
    email: el.email,
    role: el.roles,
    color: el.isActive ? 'green' : 'red',
  }))

  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              })
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close()
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })
  const columns = [
    {
      title: 'Статус',
      dataIndex: 'isActive',
      key: 'isActive',
      width: '5%',
      ...getColumnSearchProps('isActive'),
      render: (isActive) => (
        <Tag color={isActive ? 'green' : 'red'}>
          {isActive ? 'Активен' : 'Заблокирован'}
        </Tag>
      ),
    },
    {
      title: 'Имя',
      dataIndex: 'username',
      key: 'username',
      width: '20%',
      ...getColumnSearchProps('username'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '30%',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Роли',
      dataIndex: 'role',
      key: 'role',
      width: '30%',
      ...getColumnSearchProps('role'),
      render: (roles) => (
        <span>
          {roles.map((role) => {
            let color = ''
            if (role === 'admin') {
              color = 'green'
            } else if (role === 'moderator') {
              color = 'blue'
            }
            return (
              <Tag key={role} color={color}>
                {role === 'admin' ? 'Администратор' : 'Модератор'}
              </Tag>
            )
          })}
        </span>
      ),
    },
  ]
  return <Table columns={columns} dataSource={dataTable} />
}
