import axios from 'axios'
export const API_BASE_URL = 'https://svamgroup.ru/api'
export const getData = async () => {
  const token = localStorage.getItem('token')

  const { data } = await axios.get(
    `${API_BASE_URL}/orders/client/analytics?id=62cad21f5443160f6327f58a`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
  )
  return data
}

export const getUsers = async () => {
  const token = localStorage.getItem('token') // Получение токена из локального хранилища

  try {
    const { data } = await axios.get(`${API_BASE_URL}/users`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    return data
  } catch (error) {
    // Обработка ошибки
    throw error
  }
}

export const actualUser = async () => {
  const token = localStorage.getItem('token') // Получение токена из локального хранилища

  try {
    const { data } = await axios.get(`${API_BASE_URL}/auth/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    return data
  } catch (error) {
    // Обработка ошибки
    throw error
  }
}

export const getClients = async () => {
  const token = localStorage.getItem('token') // Получение токена из локального хранилища

  try {
    const { data } = await axios.get(`${API_BASE_URL}/clients`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    return data
  } catch (error) {
    // Обработка ошибки
    throw error
  }
}

export const getProducts = async () => {
  const token = localStorage.getItem('token') // Получение токена из локального хранилища

  try {
    const { data } = await axios.get(`${API_BASE_URL}/products`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    return data
  } catch (error) {
    // Обработка ошибки
    throw error
  }
}
export const getOrders = async () => {
  const token = localStorage.getItem('token') // Получение токена из локального хранилища

  try {
    const { data } = await axios.get(`${API_BASE_URL}/orders`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    return data
  } catch (error) {
    // Обработка ошибки
    throw error
  }
}

export const addUser = async (userData) => {
  try {
    const response = await axios.post(
      'ваш_путь_к_api_для_добавления_пользователя',
      userData
    )
    return response.data
  } catch (error) {
    throw error
  }
}
